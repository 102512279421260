import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import { Link } from "gatsby";
import { Helmet } from "react-helmet"

export default () => (
<>
<Helmet title="VUE - Forums" defer={false} />
<Layout>
<section className="pt-5 md:pt-5">
     <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-4/5">
          <h1 className="text-4xl lg:text-2xl xl:text-3xl font-bold leading-none">
 			VUE Forums
 		  </h1>
 		 <a class="px-4" href="https://vue-forums.uit.tufts.edu/forums/list.page">
          <h3 className="mt-10" >VUE Forums</h3>
        </a>
        <p className="text-md md:text-md mt-6 font-light">
        We are no longer creating accounts on the VUE forums, and we are in the process of deprecating the forums.  We plan to move some content to a FAQ, and possibly create a new avenue for discussion among community members.
        </p>              
 		</div>
    </div>
</section>
</Layout>
</>
)